import React from 'react';

import {
  CarFilter,
  ChildFilter,
  ConsumptionFilter,
  MileageFilter,
  ParentFilter,
  PriceFilter,
  SpecCountType,
  YearsFilter,
} from '@/types/filters/filters.types';
import { CallToAction, SEO } from '@/types/home/home.types';
import { OpeningParagraph } from '@/types/opening-paragraph/opening-paragraph.types';

import { DealerItem } from '../dealers/dealers.types';
import { VehicleExtended } from '../details-car/details-car.types';
import { Prettify } from '../utils/common.types';

export enum PriceModes {
  price,
  finance,
}

export enum ViewModes {
  tile,
  list,
}

export enum CarTileTypes {
  special,
}

export type CMSUsedCars = Prettify<{
  footer_call_to_action?: CallToAction | null;
  vehicle_detail_call_to_actions?: {
    call_to_actions_id: CallToAction | null;
  }[];
  footer_call_to_action_image?: string | null;
}>;

export interface SearchResult {
  year: string;
  name: string;
  price: string;
  currency: string;
  engine: string;
  mileage: string;
  transmission: string;
  bodyType: string;
  fuelType: string;
  doors: string;
  drivetrain: string;
  id: string;
  img: string;
  imagesCount: string;
  location: string;
  monthPrice: string;
}

export interface SliderMarks {
  label: React.ReactNode;
}

export interface SortOption {
  value: string;
  label: string;
}

export interface FinanceCalculation {
  monthlyPayment: number;
  totalRepayment: number;
  fees: number;
  registration_fee: number;
}

export interface CarSearchMeta {
  filterCount: number;
  currentPage: number;
  sort: any;
  pageSize: number;
  isIndexSearch: boolean;
}

export interface Vehicle {
  id: string;
  brand: string;
  mileage: string;
  monthlyRepayment: number;
  price: string;
  nowPrice: string;
  title: string;
  model: string;
  bodyType: string;
  mainImage: string;
  transmission: string;
  drivetrain: string;
  fuelType: string;
  doors: string;
  location: string;
  images: { directus_files_id: string }[];
  contactNumber: string;
  special: {
    sale_price_incl_vat: string;
    sale_percentage: string;
    id: string;
    special_category: {
      value: string;
      id: string;
    };
  } | null;
  imagesCount: number | string;
  dealership_id: any;
  link?: string;
  range_id: string;
  disk_drive_id: string;
  type: string;
  bodyTypeId: number;
  bodyColorId: number;
  bodyDoorsId: number;
  featuresId: number[];
  mmcode: string;
  bodyColors: string;
  year: string;
  vin: string;
  stocknr: string;
  registrationNumber: string;
  popularity: any;
  is_konfidence?: boolean;
  displayPrice?: string;
  sale_end_date?: string;
  sale_source?: string;
}

export interface NavigationFilter {
  id: number;
  name: string;
  link: string;
  brandName?: string;
}

export interface InFeaturesFilter {
  spec_features_id: {
    _in: number[] | string[];
  };
}

export interface InFilter {
  id: {
    _in: number[] | string[];
  };
}

export interface BetweenFilter {
  _between: string;
}

export interface YearInFilter {
  _in: number[];
}

export interface EqualFilter {
  _eq: string;
}

export interface BrandsRangesQuery {
  brand_id?: InFilter;
  range_id?: InFilter;
}

/* export interface ProvincesCitiesQuery {
  dealership_id?: {
    city?: InFilter;
  };
} */

export interface ProvincesDealershipsQuery {
  dealership_id?: InFilter;
}

export interface YearsQuery {
  year?: YearInFilter;
}

export interface MileageQuery {
  mileage?: BetweenFilter;
}

export interface PriceQuery {
  sales_price_including_vat?: BetweenFilter;
  display_price?: BetweenFilter;
}

export interface DoorsQuery {
  spec_doors?: InFilter;
}

export interface BodyTypesQuery {
  spec_body_type?: InFilter;
}

export interface ColorsQuery {
  spec_body_colors?: InFilter;
}

export interface FeaturesQuery {
  spec_features?: InFeaturesFilter;
}

export interface FuelTypesQuery {
  spec_fuel_type?: InFilter;
}

export interface TransmissionQuery {
  spec_transmission?: InFilter;
}

export interface DrivetrainQuery {
  spec_drivetrain?: InFilter;
}

export interface ConsumptionQuery {
  spec_litres_100km?: {
    float_value: BetweenFilter;
  };
}

export interface TypeQuery {
  type?: EqualFilter;
}

export interface SaleDateQuery {
  sale_end_date?: { _gte: string };
}

export interface RangeQuery {
  range_id?: { _in: number[] };
}

export interface ModelQuery {
  model?: string;
}

export type UserCarsFilterQuery = YearsQuery &
  MileageQuery &
  PriceQuery &
  DoorsQuery &
  BodyTypesQuery &
  ColorsQuery &
  DrivetrainQuery &
  TransmissionQuery &
  FuelTypesQuery &
  FeaturesQuery &
  ProvincesDealershipsQuery &
  TypeQuery &
  SaleDateQuery &
  RangeQuery &
  ModelQuery &
  ConsumptionQuery;

export enum VehicleStatus {
  active,
}

export interface UsedCarsBasicPageProps {
  brands: ParentFilter[];
  bodyTypes: CarFilter[];
  colourTypes: CarFilter[];
  doorsTypes: CarFilter[];
  defaultCarPrice: PriceFilter;
  defaultCarYears: YearsFilter;
  defaultCarMileages: MileageFilter;
  provincesWithDealerships: ParentFilter[];
  defaultConsumption: ConsumptionFilter;
  openingParagraph?: OpeningParagraph[];
  dealerships?: DealerItem[];
  activeDealership?: DealerItem;
}

export interface ISeoData {
  domain: string;
  id: number;
  seo_description: string;
  seo_keyword: string;
  seo_title: string;
  slug: string;
}

export interface SliderArrowProps {
  className?: string;
  onClick?: () => void;
  icon?: React.ReactNode;
}

export interface UsedCarsPageProps extends UsedCarsBasicPageProps {
  carType: VehicleType;
  headingContentText: string;
  // similarVehicles: Vehicle[];
  content?: CMSUsedCars & {
    seo: ISeoData;
    contentText: {
      search_text: string;
      popular_text: string;
      specials_text: string;
    };
  };
  // contentKonfidence: {
  //   seoKonfidence: ISeoData;
  //   contentKonfidenceText: {
  //     search_text: string;
  //     popular_text: string;
  //     specials_text: string;
  //   };
  // };
  contentDemo: {
    seoDemo: ISeoData;
    contentDemoText: {
      search_text: string;
      popular_text: string;
      specials_text: string;
    };
  };
  vehicles?: Vehicle[];
  vehiclesMeta?: CarSearchMeta;
  specialsVehicles: Vehicle[];
  fuelTypeCount: SpecCountType[];
  transmissionCount: SpecCountType[];
  driveTrainCount: SpecCountType[];
  featuresCount: SpecCountType[];
  activeDealershipId?: string | null;
}

export interface SearchPageProps extends UsedCarsBasicPageProps {
  activeType?: VehicleType;
  vehicles: Vehicle[];
  vehiclesMeta: CarSearchMeta;
  fuelTypeCount: SpecCountType[];
  transmissionCount: SpecCountType[];
  driveTrainCount: SpecCountType[];
  featuresCount?: SpecCountType[];
  seo?: SEO;
  parsedQuery?: UserCarsFilterQuery;
  initialSearchQuery?: string;
  content?: CMSUsedCars & {
    seo?: ISeoData;
    contentText?: {
      title: string;
      description: string;
    };
  };
  newFilters?: boolean;
}

export interface BrandPageProps extends SearchPageProps {
  carType?: VehicleType;
  brandQuery?: string;
  activeBrand?: ParentFilter;
  activeBrandId: string | null;
  seo?: SEO;
  content?: CMSUsedCars & {
    seo?: ISeoData;
    contentText?: {
      title: string;
      description: string;
      search_text?: string;
      popular_text?: string;
      specials_text?: string;
    };
  };
}

export interface ModelPageProps extends BrandPageProps {
  activeModel?: ChildFilter | null;
}

export interface ProvincePageProps extends SearchPageProps {
  carType: VehicleType;
  province: string;
  seo?: SEO;
  activeProvince: ParentFilter;
  content?: CMSUsedCars & {
    seo: ISeoData;
    contentText: {
      title: string;
      description: string;
    };
  };
}

export interface CityPageProps extends ProvincePageProps {
  seo: SEO;
  activeCity: ParentFilter;
}

export interface DealershipPageProps extends ProvincePageProps {
  seo?: SEO;
}

export interface BodyTypePageProps extends SearchPageProps {
  seo?: SEO;
  activeBodyType: ParentFilter;
  content?: CMSUsedCars & {
    seo: ISeoData;
    contentText: {
      title: string;
      description: string;
    };
  };
}

export interface PricePageProps extends SearchPageProps {
  seo?: SEO;
  activePrice: PriceRangeData;
  content?: CMSUsedCars & {
    seo: ISeoData;
    contentText: {
      title: string;
      description: string;
    };
  };
}

export interface PriceRangeData {
  price: number;
  fullName: string;
  min: number;
  max: number;
  link?: string;
}

export enum BrowseTypes {
  brand = 'brand',
  model = 'model',
  body = 'body',
  location = 'location',
  price = 'price',
}

export interface BrowseItemTypes {
  id: number;
  fullName?: string;
  name: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  image?: string;
  logo?: string;
  link?: string;
  specials?: number[];
  hasApprovedSpecials?: boolean;
  hasActiveSpecialDates?: boolean;
  popularity?: number;
}

export interface DiskDriveFields {
  id: string;
  DOORS: string;
  LENGTH: string;
  WIDTH: string;
  HEIGHT: string;
  SEATS: string;
  MASS: string;
  TYPE: string;
  FUEL_TYPE: string;
  FUEL_AVG: string;
  ENGINE: string;
  CYLINDERS: string;
  POWER: string;
  TORQUE: string;
  GEARBOX: string;
  DRIVE_WHEE: string;
  ABS: string;
  HANDB_POS: string;
  AIRCON: string;
  CLIM_CNT: string;
  EL_WIN: string;
  EL_WIN_DET: string;
  SLID_ROOF: string;
  EL_MIRR: string;
  MIRROR_MEM: string;
  ALLOY: string;
  AIRBAG_DET: string;
  FOG_FRONT: string;
  FOG_REAR: string;
  XENON: string;
  FULL_4X4: string;
  HEATEDSEAT: string;
  COMPUTER: string;
  SECURITY: string;
  ALARM: string;
  GEARLOCK: string;
  RADIO: string;
  CD_PLAYER: string;
  LEATHER: string;
}

export enum VehicleType {
  Used = '1',
  Konfidence = '2',
  Demo = '3',
  New = '4',
  Fleet = '5',
}

export enum SpecialFor {
  Parts = 'parts',
  Service = 'service',
  Vehicle = 'vehicle',
}

export interface TableDataType {
  key: string;
  specName: string;
  specValue: any;
}

export type UsedCarProps = {
  vehicle: VehicleExtended;
  similarVehicles: VehicleExtended[];
  defaultPrice: PriceFilter;
  dekraReports: any;
  cmsCallToActions: {
    footer_call_to_action?: CallToAction | null;
    vehicle_detail_call_to_actions?: {
      call_to_actions_id: CallToAction;
    }[];
  };
};
